
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from 'vue'
import { Class } from '@/models/course/classes/Class'
import { ColumnFields } from '@/models/components/tables/enums'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import {
  EvaluateSummary,
  FeedbackCharacter
} from '@/models/course-feedbacks/enums'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { ClassAndFeedback } from '@/models/course-feedbacks/classes/ClassAndFeedback'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import ClassAvailableSelector from '@/views/education/courses/children/details/tabs/classes-tabs/components/ClassAvailableSelector.vue'
import TagSelector from '@/views/education/courses/children/details/tabs/classes-tabs/components/TagSelector.vue'
import StaffMemberSelector from '@/components/courses/selectors/StaffMemberSelector.vue'
import FeedbackDetailTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackDetailTable.vue'
import { TagSummary } from '@/models/course-feedbacks/summaries/classes/TagSummary'
import { EntriesFeedbacks } from '@/models/course-feedbacks/classes/EntriesFeedbacks'
import { truncate } from '@/utils/math'
import { useMapper } from '@/hooks/useMapper'

export default defineComponent({
  components: {
    ClassAvailableSelector,
    TagSelector,
    StaffMemberSelector,
    FeedbackDetailTable
  },
  props: {
    selectedClass: Class,
    syllabusVersion: Number,
    evaluateSummary: String,
    staffMember: StaffMember,
    allTeachers: Array as PropType<Array<StaffMember>>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    classes: {
      type: Array as PropType<Class[]>,
      required: true
    },
    summaries: Array as PropType<ClassFeedbackSummary[]>,
    classesWithStudentAttendances: Map as PropType<
      Map<string, Map<string, string[]>>
    >
  },
  setup (props) {
    const {
      staffMember,
      evaluateSummary,
      feedbacks,
      summaries,
      selectedClass
    } = toRefs(props)
    const classes = computed(() => props.classes)
    const classesMap = useMapper<Class>('_id', classes.value)
    const selectedClassAndFeedback = computed<ClassAndFeedback>(() => {
      return feedbacks.value && selectedClass.value
        ? new ClassAndFeedback(selectedClass.value as Class, feedbacks.value)
        : new ClassAndFeedback(selectedClass.value as Class)
    })
    const classesAndFeedbackOfStaffMember = computed(() => {
      return classes.value?.map(
        c => new ClassAndFeedback(c, feedbacks.value, staffMember.value)
      )
    })
    const selectedTags = ref<FeedbackCharacter[]>([])
    const selectedStaffMember = ref<StaffMember>()
    onMounted(() => {
      selectedStaffMember.value = staffMember.value
    })
    const staff = computed<StaffMember>(() => {
      return selectedStaffMember.value
        ? ((selectedStaffMember as unknown) as StaffMember)
        : ((staffMember as unknown) as StaffMember)
    })
    const tagsSummary = computed<TagSummary>(() => {
      if (evaluateSummary.value !== EvaluateSummary.staff) {
        const classSummary = summaries.value?.find(
          summary =>
            summary.classId === selectedClassAndFeedback.value.class._id
        )
        return selectedStaffMember.value
          ? TagSummary.getTagSummaryFromSummary(
              // Some teacher
              classSummary as ClassFeedbackSummary,
              evaluateSummary.value as EvaluateSummary,
              selectedStaffMember.value.user?.personId
          )
          : TagSummary.getTagSummaryFromSummary(
              // Content
              classSummary as ClassFeedbackSummary,
              evaluateSummary.value as EvaluateSummary
          )
      }
      return new TagSummary() // Staff: No tags
    })
    const averageRate = computed<number>(() => {
      if (evaluateSummary.value !== EvaluateSummary.staff) {
        if (evaluateSummary.value === EvaluateSummary.content) {
          return (
            summaries.value?.find(
              summary =>
                summary.classId === selectedClassAndFeedback.value.class._id
            )?.contentSummary?.rating?.value || 0
          )
        }
        return (
          summaries.value
            ?.find(
              summary =>
                summary.classId === selectedClassAndFeedback.value.class._id
            )
            ?.personSummary?.get(
              selectedStaffMember.value?.user?.personId as string
            )?.rating?.value || 0
        )
      }
      const teachersGaveFeedback = EntriesFeedbacks.getPersonsGaveFeedbacks(
        feedbacks.value || new Map()
      )
      return truncate(
        EntriesFeedbacks.getAllAvarage(
          props.feedbacks || new Map(),
          teachersGaveFeedback
        ).classes.get(selectedClassAndFeedback.value.class?._id) || 0
      )
    })
    return {
      selectedClassAndFeedback,
      classesAndFeedbackOfStaffMember,
      selectedStaffMember,
      selectedTags,
      ColumnFields,
      FeedbackCharacter,
      EvaluateSummary,
      averageRate,
      tagsSummary,
      staff,
      classesMap
    }
  }
})
