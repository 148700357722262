import { EvaluateSummary, FeedbackCharacter } from '../../enums'
import { ClassFeedbackSummary } from './ClassFeedbackSummary'

export class TagSummary {
  goodTags: number
  badTags: number
  neutralTags: number
  constructor (goodTags?: number, badTags?: number, neutralTags?: number) {
    this.goodTags = goodTags || 0
    this.badTags = badTags || 0
    this.neutralTags = neutralTags || 0
  }

  get AllTags (): number {
    return this.goodTags + this.badTags + this.neutralTags
  }

  get AllNegative (): number {
    // Educacion cuenta las neutras y malas como negativas
    return this.badTags + this.neutralTags
  }

  static getTagSummaryFromSummary (classSummary: ClassFeedbackSummary, summaryRole: EvaluateSummary, teacherId?: string): TagSummary {
    if (summaryRole === EvaluateSummary.content) {
      return new TagSummary(
        Array.from(
          classSummary?.contentSummary?.tagsFeedbacks?.numberTags
            .get(FeedbackCharacter.Positive)
            ?.values() || [0]
        )?.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        ),
        Array.from(
          classSummary?.contentSummary?.tagsFeedbacks?.numberTags
            .get(FeedbackCharacter.Negative)
            ?.values() || [0]
        )?.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        ),
        Array.from(
          classSummary?.contentSummary?.tagsFeedbacks?.numberTags
            .get(FeedbackCharacter.Neutral)
            ?.values() || [0]
        )?.reduce((accumulator, currentValue) => accumulator + currentValue)
      )
    }
    return new TagSummary(
      Array.from(
        classSummary?.personSummary
          .get(teacherId as string)
          ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Positive)
          ?.values() || [0]
      ).reduce((accumulator, currentValue) => accumulator + currentValue),
      Array.from(
        classSummary?.personSummary
          .get(teacherId as string)
          ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Negative)
          ?.values() || [0]
      ).reduce((accumulator, currentValue) => accumulator + currentValue),
      Array.from(
        classSummary?.personSummary
          .get(teacherId as string)
          ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Neutral)
          ?.values() || [0]
      ).reduce((accumulator, currentValue) => accumulator + currentValue)
    )
  }
}
