import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    class: "ch-rounded-dropdown calendar-icon",
    modelValue: _ctx.selectedClass,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedClass = $event)),
    style: {"width":"13.75rem"},
    options: _ctx.allClassAndAvailable,
    placeholder: "Clase",
    optionDisabled: "disabled"
  }, {
    value: _withCtx(({ value }) => [
      _createTextVNode(" E" + _toDisplayString(_ctx.allClassAndAvailable.indexOf(value?.class)) + ": " + _toDisplayString(value.class?.calendar.formatDate('start', 'dd/MM/yyyy')), 1)
    ]),
    option: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, "E" + _toDisplayString(_ctx.allClassAndAvailable.indexOf(option?.class)) + ": " + _toDisplayString(option.class?.calendar.formatDate('start', 'dd/MM/yyyy')), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}