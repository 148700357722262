import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    modelValue: _ctx.selectedTags,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedTags = $event)),
    options: _ctx.baseTags,
    placeholder: "Tags",
    showToggleAll: false,
    class: "input-rounded",
    panelClass: "overlay-gray",
    style: {"width":"13.75rem"},
    display: "chip"
  }, {
    chip: _withCtx(({ value }) => [
      (_ctx.selectedTags)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(value === _ctx.FeedbackCharacter.Positive
            ? 'Positivas'
            : 'Negativas'), 1))
        : _createCommentVNode("", true)
    ]),
    option: _withCtx(({ option }) => [
      _createVNode(_component_Tag, {
        class: _normalizeClass([
          option === _ctx.FeedbackCharacter.Positive
            ? 'p-tag-light-green-brain'
            : 'p-tag-red-brain'
        ])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option === _ctx.FeedbackCharacter.Positive
            ? 'Positivas'
            : 'Negativas'), 1)
        ]),
        _: 2
      }, 1032, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}