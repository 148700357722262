import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { Class } from '@/models/course/classes/Class'
import { CourseFeedbackByPerson } from '../interfaces/CourseFeedBackByPerson'

export class ClassAndFeedback {
  class: Class
  disabled: boolean
  constructor (c: Class, feedbacks?: CourseFeedbackByPerson, selectedStaffMember?: StaffMember) {
    this.class = c
    if (feedbacks && selectedStaffMember) {
      this.disabled = true
      for (const pf of feedbacks.values()) {
        if (pf.feedbacks.get(this.class._id)?.entries.some(entry => entry.personId === selectedStaffMember.user?.personId)) {
          this.disabled = false
        }
      }
      return
    } else if (feedbacks) {
      this.disabled = true
      for (const pf of feedbacks.values()) {
        if (pf.feedbacks.get(this.class._id)) {
          this.disabled = false
        }
      }
      return
    }
    this.disabled = false
  }
}
