
import { defineComponent, PropType, ref, toRefs } from 'vue'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import FeedbackTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackTable.vue'
import StaffDetailTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackDetail.vue'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'

export default defineComponent({
  components: {
    FeedbackTable,
    StaffDetailTable
  },
  props: {
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    loading: Boolean,
    dateColumns: Array as PropType<Array<Column>>,
    classes: Array as PropType<Array<Class>>,
    classesWithStaffAttendances: Map as PropType<
      Map<string, Map<string, string[]>>
    >
  },
  setup (props) {
    const { classes } = toRefs(props)
    const viewDetail = ref<boolean>(false)
    const selectedClass = ref<Class>()
    const changeView = (selectedClassId?: string) => {
      selectedClass.value = classes.value?.find(c => c._id === selectedClassId)
      viewDetail.value = !viewDetail.value
    }
    return {
      viewDetail,
      changeView,
      EvaluateSummary
    }
  }
})
