
import { FeedbackCharacter } from '@/models/course-feedbacks/enums'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const selectedTags = ref<FeedbackCharacter[]>([])
    const baseTags: FeedbackCharacter[] = [
      FeedbackCharacter.Positive,
      FeedbackCharacter.Negative
    ]
    return {
      selectedTags,
      baseTags,
      FeedbackCharacter
    }
  }
})
