
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    allStaff: {
      type: Array as PropType<StaffMember[]>,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Miembros de staff'
    },
    hasTeacherStatus: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    return {
      myOptions: props.allStaff,
      StaffMember
    }
  }
})
