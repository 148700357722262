
import { ClassAndFeedback } from '@/models/course-feedbacks/classes/ClassAndFeedback'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    allClassAndAvailable: Array as PropType<ClassAndFeedback[]>
  },
  setup () {
    const selectedClass = ref<ClassAndFeedback>()
    return {
      selectedClass
    }
  }
})
