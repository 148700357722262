
import { ColumnFields } from '@/models/components/tables/enums'
import {
  EvaluateSummary,
  FeedbackCharacter
} from '@/models/course-feedbacks/enums'
import { TagSummary } from '@/models/course-feedbacks/summaries/classes/TagSummary'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { FeedbackTag } from '@/models/course-feedbacks/interfaces/dto/FeedbackTag'
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { TagsRow } from '@/models/components/tables/Row'
import { Class } from '@/models/course/classes/Class'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { EducationRouteNames } from '@/router/route-names'

export default defineComponent({
  props: {
    staffMember: {
      type: StaffMember,
      require: false
    },
    tagsSummary: {
      type: TagSummary,
      require: false
    },
    evaluateSummary: String,
    syllabusVersion: Number,
    averageRate: Number,
    studentAttendances: Number,
    selectedClass: Class,
    selectedTags: Array as PropType<Array<string>>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>
  },
  setup (props) {
    const { feedbacks, selectedClass, selectedTags, staffMember } = toRefs(
      props
    )

    const rows = computed<Array<TagsRow>>(() => {
      let resultRows: Array<TagsRow> = new Array<TagsRow>()
      if (feedbacks.value) {
        for (const v of feedbacks.value.values()) {
          if (
            !staffMember.value &&
            v.feedbacks.get(selectedClass.value?._id as string)?.contentFeedback
              .score
          ) {
            resultRows.push({
              personName: `${v.person.name} ${v.person.lastname}`,
              rating:
                (v.feedbacks.get(selectedClass.value?._id as string)
                  ?.contentFeedback.score as number) || null,
              comments:
                (v.feedbacks.get(selectedClass.value?._id as string)
                  ?.contentFeedback.comments as string[]) || '',
              tags:
                (v.feedbacks.get(selectedClass.value?._id as string)
                  ?.contentFeedback.tags as FeedbackTag[]) || null
            })
          } else if (
            staffMember.value &&
            v.feedbacks
              .get(selectedClass.value?._id as string)
              ?.entries.some(
                entry => entry.personId === staffMember.value?.user?.personId
              )
          ) {
            resultRows.push({
              personName: `${v.person.name} ${v.person.lastname}`,
              rating:
                (v.feedbacks
                  .get(selectedClass.value?._id as string)
                  ?.entries.find(
                    el => el.personId === staffMember.value?.user?.personId
                  )?.score as number) || null,
              comments:
                (v.feedbacks
                  .get(selectedClass.value?._id as string)
                  ?.entries.find(
                    el => el.personId === staffMember.value?.user?.personId
                  )?.comments as string[]) || '',
              tags:
                (v.feedbacks
                  .get(selectedClass.value?._id as string)
                  ?.entries.find(
                    el => el.personId === staffMember.value?.user?.personId
                  )?.tags as FeedbackTag[]) || null
            })
          }
        }
        if (
          selectedTags.value !== undefined &&
          selectedTags.value.length === 1 &&
          selectedTags.value.some(
            t => t === (FeedbackCharacter.Positive as string)
          )
        ) {
          resultRows = resultRows.filter(row =>
            row.tags.some(
              (tag: FeedbackTag) => tag.character === FeedbackCharacter.Positive
            )
          )
        } else if (
          selectedTags.value !== undefined &&
          selectedTags.value.length === 1 &&
          selectedTags.value.some(
            t => t === (FeedbackCharacter.Negative as string)
          )
        ) {
          resultRows = resultRows.filter(row =>
            row.tags.some(
              (tag: FeedbackTag) =>
                tag.character === FeedbackCharacter.Negative ||
                tag.character === FeedbackCharacter.Neutral
            )
          )
        }
      }
      return resultRows
    })
    return {
      EvaluateSummary,
      ColumnFields,
      FeedbackCharacter,
      EducationRouteNames,
      rows
    }
  },
  methods: {
    allTagText: (tags: FeedbackTag[]) => {
      let allText = ''
      tags.map((tag, index) => {
        allText = allText.concat(`${index + 1}- ${tag.text}.\n`)
        return 0
      })
      return allText
    }
  }
})
