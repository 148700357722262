
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'

import { ColumnFields } from '@/models/components/tables/enums'
import { truncate, isNumber } from '@/utils/math'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { EntriesFeedbacks } from '@/models/course-feedbacks/classes/EntriesFeedbacks'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { hasFeedback } from '../hasFeedback'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { useMapper } from '@/hooks/useMapper'
import { CourseAverageRating } from '@/models/course-feedbacks/interfaces/dto/CourseAverageRating'

export default defineComponent({
  props: {
    evaluateSummary: String,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    dateColumns: Array as PropType<Array<Column>>,
    classes: {
      type: Array as PropType<Array<Class>>,
      required: true
    },
    teacher: StaffMember
  },
  emits: ['changeView'],
  setup (props, context) {
    const { feedbacks, evaluateSummary } = toRefs(props)
    const classes = computed(() => props.classes)
    const classesMap = useMapper<Class>('_id', classes.value)

    let rows = ref<Array<Record<string, number | string | boolean>>>([])
    rows = computed<Array<Record<string, number | string | boolean>>>(() => {
      const result: Array<Record<
        string,
        string | number | boolean
      >> = new Array<Record<string, string | number | boolean>>()
      if (feedbacks.value && feedbacks.value.size !== 0) {
        const studentGaveFeedbacks: Map<
          string,
          number
        > = EntriesFeedbacks.getPersonsGaveFeedbacks(feedbacks.value)
        const classAvarageByFeedbacks: CourseAverageRating = EntriesFeedbacks.getAllAvarage(
          feedbacks.value,
          studentGaveFeedbacks as Map<string, number>
        )
        result.push({
          person: 'Rate Promedio',
          isFirstRow: true,
          rtg: classAvarageByFeedbacks.course.rating,
          ...Object.fromEntries(classAvarageByFeedbacks.classes.entries())
        })
        result.push({
          gray: true,
          person:
            evaluateSummary.value === EvaluateSummary.staff
              ? 'Docentes que valoran'
              : 'Estudiantes que valoran',
          rtg: '',
          ...Object.fromEntries(studentGaveFeedbacks.entries())
        })
        for (const personAndFeedbacks of feedbacks.value.values()) {
          let partial: Record<string, number | string | null> = {}
          for (const [classId, feedbackEntry] of personAndFeedbacks.feedbacks) {
            partial = {
              ...partial,
              [classId]: feedbackEntry.getAvarage()
            }
          }
          result.push({
            person: personAndFeedbacks.person.fullName,
            rtg: EntriesFeedbacks.getAllAvarageByStudent(
              personAndFeedbacks.feedbacks,
              (classes.value as Class[]) || []
            ),
            ...partial
          })
        }
      }
      return result
    })
    return {
      rows,
      EvaluateSummary,
      ColumnFields,
      truncate,
      hasFeedback,
      isNumber,
      changeView: (classId: string): void =>
        context.emit('changeView', classId),
      classesMap
    }
  },
  methods: {
    getClassNameByAVGRating: (data?: unknown): string => {
      return !isNumber(data as number)
        ? 'blank'
        : data && Number(data) >= 4.7
          ? 'c-green'
          : 'c-red'
    },
    getClassNameByRating: (data?: unknown): string | null => {
      return !isNumber(data as number) || (data && Number(data) >= 4.7)
        ? 'blank'
        : data && Number(data) >= 3
          ? 'bg-yellow'
          : 'bg-red'
    },
    getTestRatingName: (isFirstRow = false): string => {
      return isFirstRow ? 'ratingAVG' : 'rating'
    }
  }
})
