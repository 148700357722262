import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "teachers-selectors",
  class: "p-d-flex p-pl-5 leftTabView",
  ref: "teachersSelectorRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_GeneralFeedback = _resolveComponent("GeneralFeedback")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_ContentFeedback = _resolveComponent("ContentFeedback")!
  const _component_TeacherFeedback = _resolveComponent("TeacherFeedback")!
  const _component_TutorFeedback = _resolveComponent("TutorFeedback")!
  const _component_StaffFeedback = _resolveComponent("StaffFeedback")!
  const _component_AfterClassFeedback = _resolveComponent("AfterClassFeedback")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
      : (_openBlock(), _createBlock(_component_TabView, {
          key: 1,
          id: "tabNav",
          class: "rounded-tabview",
          activeIndex: _ctx.tabIndex,
          "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabIndex = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, { header: "General" }, {
              default: _withCtx(() => [
                _createVNode(_component_GeneralFeedback, {
                  allTeachers: _ctx.teachers,
                  feedbacks: _ctx.feedbacks.fromStudents.classes,
                  loading: _ctx.loading || _ctx.staffIsLoading,
                  dateColumns: _ctx.classesColumns,
                  classes: _ctx.course.classes,
                  classesWithStudentAttendances: _ctx.classesWithStudentAttendances
                }, null, 8, ["allTeachers", "feedbacks", "loading", "dateColumns", "classes", "classesWithStudentAttendances"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, { header: "Contenido" }, {
              default: _withCtx(() => [
                _createVNode(_component_ContentFeedback, {
                  allTeachers: _ctx.teachers,
                  syllabusVersion: _ctx.syllabus?.version,
                  teachersSelectorDiv: _ctx.teachersSelectorRef,
                  summaries: _ctx.summaries,
                  dateColumns: _ctx.classesColumns,
                  classes: _ctx.course.classes,
                  feedbacks: _ctx.feedbacks.fromStudents.classes,
                  classesWithStudentAttendances: _ctx.classesWithStudentAttendances
                }, null, 8, ["allTeachers", "syllabusVersion", "teachersSelectorDiv", "summaries", "dateColumns", "classes", "feedbacks", "classesWithStudentAttendances"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, { header: "Profesores" }, {
              default: _withCtx(() => [
                _createVNode(_component_TeacherFeedback, {
                  allTeachers: _ctx.teachers,
                  teachersSelectorDiv: _ctx.teachersSelectorRef,
                  activeIndex: _ctx.tabIndex,
                  summaries: _ctx.summaries,
                  dateColumns: _ctx.classesColumns,
                  classes: _ctx.course.classes,
                  feedbacks: _ctx.feedbacks.fromStudents.classes,
                  classesWithStudentAttendances: _ctx.classesWithStudentAttendances
                }, null, 8, ["allTeachers", "teachersSelectorDiv", "activeIndex", "summaries", "dateColumns", "classes", "feedbacks", "classesWithStudentAttendances"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, { header: "Tutores" }, {
              default: _withCtx(() => [
                _createVNode(_component_TutorFeedback, {
                  allTutors: _ctx.tutors,
                  teachersSelectorDiv: _ctx.teachersSelectorRef,
                  activeIndex: _ctx.tabIndex,
                  summaries: _ctx.summaries,
                  dateColumns: _ctx.classesColumns,
                  classes: _ctx.course.classes,
                  feedbacks: _ctx.feedbacks.fromStudents.classes,
                  classesWithStudentAttendances: _ctx.classesWithStudentAttendances
                }, null, 8, ["allTutors", "teachersSelectorDiv", "activeIndex", "summaries", "dateColumns", "classes", "feedbacks", "classesWithStudentAttendances"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, { header: "Equipo" }, {
              default: _withCtx(() => [
                _createVNode(_component_StaffFeedback, {
                  feedbacks: _ctx.feedbacks.fromStaff.classes,
                  loading: _ctx.loading || _ctx.staffIsLoading,
                  dateColumns: _ctx.classesColumns,
                  classes: _ctx.course.classes,
                  classesWithStaffAttendances: _ctx.classesWithStaffAttendances
                }, null, 8, ["feedbacks", "loading", "dateColumns", "classes", "classesWithStaffAttendances"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, { header: "After Classes" }, {
              default: _withCtx(() => [
                _createVNode(_component_AfterClassFeedback, {
                  staff: _ctx.teachers,
                  course: _ctx.course,
                  syllabus: _ctx.syllabus,
                  feedbacks: _ctx.feedbacks.fromStudents.afterClasses,
                  dateColumns: _ctx.afterClassesColumns
                }, null, 8, ["staff", "course", "syllabus", "feedbacks", "dateColumns"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["activeIndex"]))
  ], 64))
}