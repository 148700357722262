import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-34b45fe6")
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  id: "detail-selectors",
  class: "p-d-flex p-jc-start"
}
const _hoisted_3 = { class: "p-d-flex p-jc-between p-p-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_ClassAvailableSelector = _resolveComponent("ClassAvailableSelector")!
  const _component_TagSelector = _resolveComponent("TagSelector")!
  const _component_StaffMemberSelector = _resolveComponent("StaffMemberSelector")!
  const _component_FeedbackDetailTable = _resolveComponent("FeedbackDetailTable")!

  return (!_ctx.selectedClassAndFeedback || ([_ctx.EvaluateSummary.teacher, _ctx.EvaluateSummary.tutor].includes(_ctx.evaluateSummary) && (!_ctx.selectedStaffMember || !_ctx.allTeachers)))
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.selectedClassAndFeedback)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_ClassAvailableSelector, {
                    modelValue: _ctx.selectedClassAndFeedback,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedClassAndFeedback = $event)),
                    allClassAndAvailable: _ctx.classesAndFeedbackOfStaffMember
                  }, null, 8, ["modelValue", "allClassAndAvailable"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.evaluateSummary !== _ctx.EvaluateSummary.staff)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_TagSelector, {
                    modelValue: _ctx.selectedTags,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedTags = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (
            _ctx.evaluateSummary === _ctx.EvaluateSummary.teacher ||
              _ctx.evaluateSummary === _ctx.EvaluateSummary.tutor
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_StaffMemberSelector, {
                    modelValue: _ctx.selectedStaffMember,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedStaffMember = $event)),
                    allStaff: _ctx.allTeachers
                  }, null, 8, ["modelValue", "allStaff"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_FeedbackDetailTable, {
          staffMember: _ctx.selectedStaffMember,
          syllabusVersion: _ctx.syllabusVersion,
          evaluateSummary: _ctx.evaluateSummary,
          selectedClass: _ctx.selectedClassAndFeedback.class,
          selectedTags: _ctx.selectedTags,
          feedbacks: _ctx.feedbacks,
          tagsSummary: _ctx.tagsSummary,
          averageRate: _ctx.averageRate,
          studentAttendances: 
        _ctx.selectedStaffMember && _ctx.selectedClassAndFeedback.class?._id
          ? _ctx.classesWithStudentAttendances[_ctx.selectedClassAndFeedback.class?._id][_ctx.selectedStaffMember.user?.personId]?.length
          || _ctx.classesMap?.get(_ctx.selectedClassAndFeedback.class?._id)?.studentAttendances.length
          : _ctx.classesMap?.get(_ctx.selectedClassAndFeedback.class?._id)?.studentAttendances.length
      
        }, null, 8, ["staffMember", "syllabusVersion", "evaluateSummary", "selectedClass", "selectedTags", "feedbacks", "tagsSummary", "averageRate", "studentAttendances"])
      ]))
}