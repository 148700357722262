import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "p-d-flex p-mb-5",
  ref: "selectorContent"
}
const _hoisted_2 = {
  class: "p-d-flex p-mb-5",
  ref: "selectorStaff"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_FeedbackTable = _resolveComponent("FeedbackTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_AfterClassContentFeedback = _resolveComponent("AfterClassContentFeedback")!
  const _component_AfterClassStaffFeedback = _resolveComponent("AfterClassStaffFeedback")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createBlock(_component_TabView, {
        key: 1,
        class: "icon-center-tabview",
        activeIndex: _ctx.tabIndex,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabIndex = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, { header: "General" }, {
            default: _withCtx(() => [
              _createVNode(_component_FeedbackTable, {
                evaluateSummary: _ctx.EvaluateSummary.general,
                feedbacks: _ctx.feedbacks,
                dateColumns: _ctx.dateColumns,
                classes: _ctx.course.afterClasses
              }, null, 8, ["evaluateSummary", "feedbacks", "dateColumns", "classes"])
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, { header: "Contenido" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, null, 512),
              _createVNode(_component_AfterClassContentFeedback, {
                allTeachers: _ctx.staffInFeedbacks,
                syllabusVersion: _ctx.syllabus?.version,
                teachersSelectorDiv: _ctx.selector,
                summaries: _ctx.afterClassSummaries,
                dateColumns: _ctx.dateColumns,
                classes: _ctx.course.afterClasses,
                feedbacks: _ctx.feedbacks,
                classesWithStudentAttendances: new Map()
              }, null, 8, ["allTeachers", "syllabusVersion", "teachersSelectorDiv", "summaries", "dateColumns", "classes", "feedbacks", "classesWithStudentAttendances"])
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, { header: "Staff" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, null, 512),
              _createVNode(_component_AfterClassStaffFeedback, {
                allTeachers: _ctx.staffInFeedbacks,
                teachersSelectorDiv: _ctx.selectorStaff,
                activeIndex: _ctx.tabIndex,
                summaries: _ctx.afterClassSummaries,
                dateColumns: _ctx.dateColumns,
                classes: _ctx.course.afterClasses,
                feedbacks: _ctx.feedbacks,
                classesWithStudentAttendances: new Map()
              }, null, 8, ["allTeachers", "teachersSelectorDiv", "activeIndex", "summaries", "dateColumns", "classes", "feedbacks", "classesWithStudentAttendances"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeIndex"]))
}