
import { defineComponent, ref, onMounted, computed, Ref, PropType, watch } from 'vue'
import { DateTime } from 'luxon'
import GeneralFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/GeneralFeedback.vue'
import TeacherFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/TeacherFeedback.vue'
import TutorFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/TutorFeedback.vue'
import ContentFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/ContentFeedback.vue'
import StaffFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/StaffFeedback.vue'
import AfterClassFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/AfterClassFeedback.vue'
import { CourseFeedbackAPI } from '@/api/feedbacks.api'
import { FormatDates } from '@/models/enums'
import { Course } from '@/models/course/classes/Course'
import { CourseGeneralFeedback } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { Student } from '@/models/course/builder/student.builder'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { PersonRoles } from '@/models/person/enums'
import { Column } from '@/models/components/tables/Column'
import { ClassId, StudentId, TutorId } from '@/utils/types'
import { CourseAPI } from '@/api/course.api'

export default defineComponent({
  components: {
    GeneralFeedback,
    TeacherFeedback,
    TutorFeedback,
    ContentFeedback,
    StaffFeedback,
    AfterClassFeedback
  },
  props: {
    course: Course,
    syllabus: Object as PropType<Syllabus>,
    students: Array as PropType<Student[]>,
    summaries: Array as PropType<ClassFeedbackSummary[]>,
    staff: Array as PropType<StaffMember[]>,
    staffIsLoading: Boolean
  },
  setup (props, ctx) {
    const course = computed<Course>(() => props.course as Course)
    const summaries = computed<ClassFeedbackSummary[]>(() => props.summaries as ClassFeedbackSummary[])
    const loading = ref<boolean>(false)
    const classesWithStudentAttendances = ref<
      Record<ClassId, Record<TutorId, StudentId[]>>
    >()
    const staff = computed<StaffMember[]>(() => props.staff as StaffMember[])
    const teachersSelectorRef = ref<HTMLDivElement>() as Ref<HTMLDivElement>
    const feedbacks = ref<CourseGeneralFeedback>({
      fromStudents: {
        classes: new Map(),
        afterClasses: new Map()
      },
      fromStaff: {
        classes: new Map()
      }
    })

    const classesColumns = computed<Column[]>(() =>
      course.value?.classes?.map(c => ({
        field: c._id,
        header: DateTime.fromISO(
          c.calendar.start
            ? c.calendar.start.toString()
            : new Date().toISOString()
        ).toFormat(FormatDates.LatinAmericaShort)
      }))
    )

    const afterClassesColumns = computed<Column[]>(() =>
      course.value?.afterClasses?.map(c => ({
        field: c._id,
        header: DateTime.fromISO(
          c.calendar.start
            ? c.calendar.start.toString()
            : new Date().toISOString()
        ).toFormat(FormatDates.LatinAmericaShort)
      }))
    )

    const classesWithStaffAttendances = computed<Map<string, string[]>>(() => {
      const mapper = new Map()
      course.value.classes.map(courseClass => {
        mapper.set(courseClass._id, courseClass.staffAttendances)
        return 0
      })
      return mapper
    })
    watch(summaries, async () => {
      try {
        loading.value = true
        feedbacks.value.fromStudents.classes = await CourseFeedbackAPI.findAllByStudents(
          course.value._id
        )
        feedbacks.value.fromStudents.afterClasses = await CourseFeedbackAPI.findAllAfterClass(
          course.value._id
        )
        feedbacks.value.fromStaff.classes = await CourseFeedbackAPI.findAllByStaff(
          course.value._id
        )
        classesWithStudentAttendances.value = await CourseAPI.findClassesAndTutoringAttendancesByCourseId(
          course.value._id
        )
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    })

    onMounted(async () => {
      try {
        loading.value = true
        if (!staff.value || staff.value.length === 0) {
          ctx.emit('loadStaff')
        }
        feedbacks.value.fromStudents.classes = await CourseFeedbackAPI.findAllByStudents(
          course.value._id
        )
        feedbacks.value.fromStudents.afterClasses = await CourseFeedbackAPI.findAllAfterClass(
          course.value._id
        )
        feedbacks.value.fromStaff.classes = await CourseFeedbackAPI.findAllByStaff(
          course.value._id
        )
        classesWithStudentAttendances.value = await CourseAPI.findClassesAndTutoringAttendancesByCourseId(
          course.value._id
        )
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    })

    const tutors = computed((): StaffMember[] =>
      StaffMember.sortByUnenroll(
        staff.value.filter(teacher => teacher.user?.role === PersonRoles.Tutor)
      )
    )
    const teachers = computed((): StaffMember[] =>
      StaffMember.sortByUnenroll(
        staff.value.filter(
          teacher => teacher.user?.role === PersonRoles.Teacher
        )
      )
    )

    return {
      feedbacks,
      loading,
      tabIndex: ref<number>(0),
      teachers,
      tutors,
      teachersSelectorRef,
      classesColumns,
      afterClassesColumns,
      classesWithStudentAttendances,
      classesWithStaffAttendances
    }
  }
})
