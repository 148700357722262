
import { defineComponent, onMounted, PropType, ref, toRefs, watch } from 'vue'
import StaffMemberSelector from '@/components/courses/selectors/StaffMemberSelector.vue'
import TutorTagTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/TagTable.vue'
import TutorDetailTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackDetail.vue'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'

export default defineComponent({
  components: {
    StaffMemberSelector,
    TutorTagTable,
    TutorDetailTable
  },
  props: {
    teachersSelectorDiv: Object as PropType<HTMLDivElement>,
    activeIndex: Number,
    allTutors: Array as PropType<StaffMember[]>,
    summaries: Array as PropType<ClassFeedbackSummary[]>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    classes: Array as PropType<Class[]>,
    dateColumns: Array as PropType<Column[]>,
    classesWithStudentAttendances: Map as PropType<Map<string, Map<string, string[]>>>
  },
  setup (props) {
    const { allTutors, classes } = toRefs(props)
    const viewDetail = ref<boolean>(false)
    const selectedTutor = ref<StaffMember>()
    const selectedClass = ref<Class>()
    onMounted(() => {
      selectedTutor.value = (allTutors.value as StaffMember[])[0]
    })
    watch(allTutors, () => {
      selectedTutor.value = (allTutors.value as StaffMember[])[0]
      return 0
    })
    const changeView = (selectedClassId?: string) => {
      selectedClass.value = classes.value?.find(c => c._id === selectedClassId)
      viewDetail.value = !viewDetail.value
    }
    return {
      selectedTutor,
      selectedClass,
      viewDetail,
      changeView,
      EvaluateSummary
    }
  }
})
