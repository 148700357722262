import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_FeedbackTable = _resolveComponent("FeedbackTable")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createBlock(_component_FeedbackTable, {
        key: 1,
        evaluateSummary: _ctx.EvaluateSummary.general,
        feedbacks: _ctx.feedbacks,
        dateColumns: _ctx.dateColumns,
        classes: _ctx.classes,
        classesWithPeopleAttendances: _ctx.classesWithStudentAttendances,
        teacher: _ctx.teacher
      }, null, 8, ["evaluateSummary", "feedbacks", "dateColumns", "classes", "classesWithPeopleAttendances", "teacher"]))
}