
import { defineComponent, PropType, computed } from 'vue'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import FeedbackTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackTable.vue'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { StaffMember } from '@/models/course/builder/staffMember.builder'

export default defineComponent({
  components: {
    FeedbackTable
  },
  props: {
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    loading: Boolean,
    dateColumns: Array as PropType<Array<Column>>,
    classes: Array as PropType<Array<Class>>,
    classesWithStudentAttendances: Map as PropType<
      Map<string, Map<string, string[]>>
    >,
    allTeachers: Array as PropType<StaffMember[]>
  },
  setup (props) {
    const allTeachers = computed<StaffMember[]>(
      () => props.allTeachers as StaffMember[]
    )
    const teacher = computed<StaffMember>(() => allTeachers.value[0])
    return {
      EvaluateSummary,
      teacher
    }
  }
})
