import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_FeedbackTable = _resolveComponent("FeedbackTable")!
  const _component_StaffDetailTable = _resolveComponent("StaffDetailTable")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_ctx.viewDetail)
      ? (_openBlock(), _createBlock(_component_FeedbackTable, {
          key: 1,
          evaluateSummary: _ctx.EvaluateSummary.staff,
          feedbacks: _ctx.feedbacks,
          dateColumns: _ctx.dateColumns,
          classes: _ctx.classes,
          classesWithPeopleAttendances: _ctx.classesWithStaffAttendances,
          onChangeView: _ctx.changeView
        }, null, 8, ["evaluateSummary", "feedbacks", "dateColumns", "classes", "classesWithPeopleAttendances", "onChangeView"]))
      : (_openBlock(), _createBlock(_component_StaffDetailTable, {
          key: 2,
          selectedClass: _ctx.selectedClass,
          evaluateSummary: _ctx.EvaluateSummary.staff,
          feedbacks: _ctx.feedbacks,
          classes: _ctx.classes,
          summaries: _ctx.summaries,
          classesWithStudentAttendances: _ctx.classesWithStaffAttendances
        }, null, 8, ["selectedClass", "evaluateSummary", "feedbacks", "classes", "summaries", "classesWithStudentAttendances"]))
}