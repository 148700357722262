
import { defineComponent, ref, computed, PropType, toRefs, onMounted, onBeforeMount } from 'vue'
import FeedbackTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackTable.vue'
import AfterClassContentFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/ContentFeedback.vue'
import AfterClassStaffFeedback from '@/views/education/courses/children/details/tabs/classes-tabs/TeacherFeedback.vue'
import { Course } from '@/models/course/classes/Course'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { CourseFeedback } from '@/models/course-feedbacks/classes/CourseFeedback'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CourseFeedbackSummaryAPI } from '@/api/feedbacks.api'
import { Column } from '@/models/components/tables/Column'

export default defineComponent({
  components: {
    FeedbackTable,
    AfterClassContentFeedback,
    AfterClassStaffFeedback
  },
  props: {
    course: Course,
    syllabus: Object as PropType<Syllabus>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    staff: Array as PropType<StaffMember[]>,
    dateColumns: Array as PropType<Column[]>
  },
  setup (props) {
    const loading = ref<boolean>(false)
    const { course, staff, feedbacks } = toRefs(props)
    const selectorContent = ref<HTMLDivElement>()
    const selectorStaff = ref<HTMLDivElement>()

    const afterClassSummaries = ref<ClassFeedbackSummary[]>()
    onBeforeMount((): void => {
      loading.value = true
    })
    onMounted(async () => {
      try {
        afterClassSummaries.value = await CourseFeedbackSummaryAPI.findAfterClass(course.value?._id as string)
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    })
    const staffMentionedInFeedbacks = computed<string[]>(() => {
      if (feedbacks.value) {
        return CourseFeedback.getStaffFromFeedbacks(feedbacks.value)
      }
      return []
    })
    const staffInFeedbacks = computed<StaffMember[]>(() => {
      if (staffMentionedInFeedbacks.value && staff.value) {
        return staff.value.filter(s => staffMentionedInFeedbacks.value.includes(s.user?.personId as string))
      }
      return []
    })

    return {
      staffInFeedbacks,
      selectorContent,
      selectorStaff,
      loading,
      tabIndex: ref<number>(0),
      EvaluateSummary,
      afterClassSummaries,
      Map
    }
  }
})
