
import { defineComponent, PropType, ref, toRefs, computed } from 'vue'
import ContentTagTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/TagTable.vue'
import ContentDetailTable from '@/views/education/courses/children/details/tabs/classes-tabs/components/FeedbackDetail.vue'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { StaffMember } from '@/models/course/builder/staffMember.builder'

export default defineComponent({
  components: {
    ContentTagTable,
    ContentDetailTable
  },
  props: {
    allTeachers: Array as PropType<StaffMember[]>,
    teachersSelectorDiv: Object as PropType<HTMLDivElement>,
    syllabusVersion: Number,
    summaries: Array as PropType<ClassFeedbackSummary[]>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    classes: Array as PropType<Class[]>,
    dateColumns: Array as PropType<Column[]>,
    classesWithStudentAttendances: Map as PropType<Map<string, Map<string, string[]>>>
  },
  setup (props) {
    const { classes } = toRefs(props)
    const viewDetail = ref<boolean>(false)
    const selectedClass = ref<Class>()
    const changeView = (selectedClassId?: string) => {
      selectedClass.value = classes.value?.find(c => c._id === selectedClassId)
      viewDetail.value = !viewDetail.value
    }
    const allTeachers = computed<StaffMember[]>(() => props.allTeachers as StaffMember[])
    const teacher = computed<StaffMember>(() => allTeachers.value[0])

    return {
      selectedClass,
      viewDetail,
      changeView,
      EvaluateSummary,
      teacher
    }
  }
})
