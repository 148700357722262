import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-ai-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoderBadge = _resolveComponent("CoderBadge")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    class: "ch-rounded-dropdown",
    options: _ctx.allStaff,
    placeholder: _ctx.placeholder
  }, {
    option: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(option.profile?.name) + " " + _toDisplayString(option.profile?.lastName), 1),
        (_ctx.hasTeacherStatus)
          ? (_openBlock(), _createBlock(_component_CoderBadge, {
              key: 0,
              text: _ctx.StaffMember.getTeacherStatus(option).name,
              backgroundColor: _ctx.StaffMember.getTeacherStatus(option).color,
              fontSize: "12px",
              padding: "0px 4px",
              rounded: ""
            }, null, 8, ["text", "backgroundColor"]))
          : _createCommentVNode("", true)
      ])
    ]),
    value: _withCtx(({ value }) => [
      value
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, _toDisplayString(value.profile?.name) + " " + _toDisplayString(value.profile?.lastName), 1),
            (_ctx.hasTeacherStatus)
              ? (_openBlock(), _createBlock(_component_CoderBadge, {
                  key: 0,
                  text: _ctx.StaffMember.getTeacherStatus(value).name,
                  backgroundColor: _ctx.StaffMember.getTeacherStatus(value).color,
                  fontSize: "12px",
                  padding: "0px 4px",
                  rounded: ""
                }, null, 8, ["text", "backgroundColor"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["options", "placeholder"]))
}