
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'

import { ColumnFields } from '@/models/components/tables/enums'
import {
  EvaluateSummary,
  FeedbackCharacter
} from '@/models/course-feedbacks/enums'
import { truncate, isNumber } from '@/utils/math'
import { EducationRouteNames } from '@/router/route-names'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { Column } from '@/models/components/tables/Column'
import { Class } from '@/models/course/classes/Class'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { PersonRoles } from '@/models/person/enums'
import { CSSColors } from '@/models/enums'
import { hasFeedback } from '../hasFeedback'
import { useMapper } from '@/hooks/useMapper'

export default defineComponent({
  props: {
    loading: Boolean,
    syllabusVersion: Number,
    summaries: Array as PropType<Array<ClassFeedbackSummary>>,
    teacher: StaffMember,
    evaluateSummary: String,
    allTeachers: Array as PropType<Array<StaffMember>>,
    feedbacks: Map as PropType<CourseFeedbackByPerson>,
    dateColumns: Array as PropType<Array<Column>>,
    classes: {
      type: Array as PropType<Class[]>,
      required: true
    },
    afterClasses: Array as PropType<Class[]>,
    classesWithStudentAttendances: Map as PropType<
      Map<string, Map<string, string[]>>
    >
  },
  emits: ['changeView'],
  setup (props, context) {
    const { summaries, teacher } = toRefs(props)
    const classes = computed(() => props.classes)
    const classesMap = useMapper<Class>('_id', classes.value)
    const defaultColumns: Ref<Array<Column>> = ref([
      { field: ColumnFields.TagText, header: '' } as Column,
      { field: ColumnFields.Total, header: 'Total' } as Column
    ])

    let rows = ref<Array<Record<string, number | string>>>([])
    // Building Rows
    rows = computed<Array<Record<string, number | string>>>(() => {
      const result: Array<Record<string, string | number>> = new Array<
        Record<string, string | number>
      >()
      if (summaries.value && classes.value) {
        const teacherAvarageBySummaries = ClassFeedbackSummary.getAverageByClass(
          summaries.value,
          teacher.value
        )
        const positiveTags = ClassFeedbackSummary.getAllTagsTexts(
          summaries.value,
          FeedbackCharacter.Positive,
          teacher.value?.user?.personId
        )
        const negativeTags = ClassFeedbackSummary.getAllTagsTexts(
          summaries.value,
          FeedbackCharacter.Negative,
          teacher.value?.user?.personId
        )
        result.push({
          firstRow: 1,
          text: 'Rating',
          total: teacherAvarageBySummaries.course.rating,
          ...Object.fromEntries(teacherAvarageBySummaries.classes.entries())
        })
        result.push({
          secondRow: 1,
          text: '',
          total: '',
          ...Object.fromEntries(
            ClassFeedbackSummary.getdetailFeedbackCountByClass(
              summaries.value,
              teacher.value?.user?.personId
            )
          )
        })
        result.push({
          grayRow: 1,
          text: 'Tags',
          total: '',
          ...Object(null)
        })
        for (const tag of positiveTags) {
          result.push({
            text: tag,
            isTag: 1,
            total: ClassFeedbackSummary.countTagInAll(
              ClassFeedbackSummary.getTagCountByClass(
                tag,
                summaries.value,
                FeedbackCharacter.Positive,
                teacher.value?.user?.personId
              )
            ),
            green: 1,
            ...Object.fromEntries(
              ClassFeedbackSummary.getTagCountByClass(
                tag,
                summaries.value,
                FeedbackCharacter.Positive,
                teacher.value?.user?.personId
              )
            )
          })
        }
        for (const tag of negativeTags) {
          result.push({
            text: tag,
            isTag: 1,
            total: ClassFeedbackSummary.countTagInAll(
              ClassFeedbackSummary.getTagCountByClass(
                tag,
                summaries.value,
                FeedbackCharacter.Negative,
                teacher.value?.user?.personId
              )
            ),
            ...Object.fromEntries(
              ClassFeedbackSummary.getTagCountByClass(
                tag,
                summaries.value,
                FeedbackCharacter.Negative,
                teacher.value?.user?.personId
              )
            )
          })
        }
      }
      return result
    })
    const changeView = (selectedClass: string) =>
      context.emit('changeView', selectedClass)
    return {
      defaultColumns,
      rows,
      ColumnFields,
      EvaluateSummary,
      changeView,
      hasFeedback,
      truncate,
      EducationRouteNames,
      PersonRoles,
      StaffMember,
      isNumber,
      CSSColors,
      classesMap
    }
  },
  methods: {
    getClassNameByAVGRating: (data?: unknown): string => {
      return !isNumber(data as number)
        ? 'blank'
        : data && Number(data) >= 4.7
          ? 'c-green'
          : 'c-red'
    }
  }
})
