import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { IPersonFeedbacks } from '@/models/course-feedbacks/interfaces/IPersonFeedbacks'

const hasFeedbackCondition = (classId: string, pf: IPersonFeedbacks, evaluateSummary: EvaluateSummary, personId?: string) => {
  const opts = {
    [EvaluateSummary.staff]: pf.feedbacks.get(classId),
    [EvaluateSummary.teacher]: pf.feedbacks.get(classId)?.entries.find(teacher => teacher.personId === personId),
    [EvaluateSummary.tutor]: pf.feedbacks.get(classId)?.entries.find(teacher => teacher.personId === personId),
    [EvaluateSummary.content]: pf.feedbacks.get(classId)?.contentFeedback,
    [EvaluateSummary.nps]: null,
    [EvaluateSummary.general]: null
  }
  return opts[evaluateSummary] || null
}

export const hasFeedback = (feedbacks: CourseFeedbackByPerson, classId: string, evaluateSummary: EvaluateSummary, personId?: string): boolean => {
  for (const pf of feedbacks.values() || []) {
    if (hasFeedbackCondition(classId, pf, evaluateSummary, personId)) {
      return true
    }
  }
  return false
}
